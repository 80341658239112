import React, {Component} from 'react';

import CaseStudy from '../../../components/CaseStudy'
import evoke from './_evoke'
import Challenge1 from './challenge_1.png';
import Challenge2 from './challenge_2.png';
import Challenge3 from './challenge_3.png';

import '../content.scss';

export default class Home extends Component {
  render() {
    return (
      <CaseStudy caseStudy={evoke}>
        <h1>The Lean PM: An Agile Approach to Project Management</h1>
        <hr/>
        <p>In 2016, Mobility Labs was approached to assist The World Bank in creating the user experience and user
        interface design for an online, project based learning game – Evoke. When Mobility Labs began their contract
        with The World Bank, project milestones loomed as The World Bank struggled to organize a fleet of resources
        towards a united goal. Mobility Labs’ lead the fractured teams through a Strategy Alignment Day that produced
        a unified vision for the project with clear requirements and realistic expectations for Evoke.</p>
        <h2>Success Overview</h2>
        <p>Mobility Labs’ success in this Strategy Alignment Day service, is part of their greater process to change
        the way in which contractors and stakeholders work together. This allows Mobility Labs to bring order to
        disorganization and to create clear roadmaps for projects and products to follow, allowing all teams greater
        visibility into how their digital products and projects can be used to further their mission, campaign or
        business.</p>
        <h2>The success of the Strategy Alignment Day from Mobility Labs resulted in…</h2>
        <p>A single, united project plan that established clear milestones, priority and technical requirements that
        all teams worked from to improve efficiency. An improved communication strategy to support Agile/SCRUM
        practices in the multi-team workflow. Collaborative designs that surfaced hidden requirements for the critical
        workflow and interaction points within Evoke.</p>
        <div className="challenges">
          <h1>Challenges</h1>
          <hr/>
          <p>Prior to employing Mobility Labs, The World Bank utilized a mix of in-house and dispersed designers and
          contract agents supplying technical and design expertise towards the Evoke project. Because of the dispersed
          organization of resources, The World Bank’s Evoke project faced significant challenges.</p>
          <div className="challenge" id="challenge-1">
            <div className="challenge-detail">
              <img className="img-fluid all-white" src={Challenge1} role="presentation"/>
              <h2>Challenge 1: Lack of central vision for the key requirements of the application</h2>
              <p>In short, it was difficult to assess what was needed for the project when key requirements had not
              yet been defined. With development, clear requirements and interaction details are critical into
              translating end-user and business needs clearly into the growing application.
              By understanding not only the minutia of individual feature requests, but the big pictures of the
              application – developers are better able to plan the architecture of the application to provide the best
              experience for the user and company.</p>
            </div>
            <section className="challenge-solution">
              <h2>How we solved this challenge</h2>
              <ol>
                <li>
                  <h3>1. Mobility Labs created a central, unified vision for the Evoke application</h3>
                  <p>In approaching the abundance of features and needs from The World Bank, Mobility Labs lead a
                  Strategy Alignment Day meeting with the end goal of combining the many documents containing
                  features, design ideasand timelines into one unified master project plan.</p>
                  <p>By analyzing existing functionality and diving to the root of The World Bank’s user needs, Mobility
                  Labs was able to create a roadmap of features complete with work effort estimates to be used by all
                  teams for planning and development.</p>
                </li>
                <li>
                  <h3>2. A defined delivery date and clear process for managing changes or additional
                  requests for the project</h3>
                  <p>By creating clear sprint timelines based on average velocity, Mobility Labs was able to give
                  realistic boundaries based on proven results for stakeholders to better dictate priority and task
                  importance.</p>
                  <p>This allowed the stakeholders an opportunity to surface clear expectations to higher-level
                  executives, partnering organizations and other team members that was based on realistic expectations
                  for improved planning, budgeting and management.</p>
                </li>
              </ol>
            </section>
            <blockquote className="blockquote long">Mobility Labs’ core practices are rooted in proven development
              methodology built around the idea of digesting and translating high-level needs or wants into technical
              documentation. <br/>This transition and pivot point is critical to the project’s success as it allows the
              design and development team to accomplish more within the milestone timelines. Approaching problems with
              a diverse background that is supported by fundamental teachings in the field allow Mobility Labs the
              unique perspective into the best methods for bringing order to chaotic features.</blockquote>
          </div>
          <div className="challenge" id="challenge-2">
            <div className="challenge-detail">
              <img className="img-fluid" src={Challenge2} role="presentation"/>
              <h2>Challenge 2: Inability to forecast and plan deliverables</h2>
              <p>The expression time is money takes a very literal form with web development projects. Every hour the
              development or design team spends on one task, is an hour they do not spend on another.</p>
              <p>The complexity of these choices can sink projects if teams are unable to unite behind clear milestone
               markers for tasks within those milestones as well as an understanding for tasks that fall outside of
               those boundaries.</p>
            </div>
            <section className="challenge-solution">
              <h2>How we solved this challenge</h2>
              <ol>
                <li>
                  <h3>1. Mobility Labs calculated velocity estimates to better forecast deliverable
                  milestones.</h3>
                  <p>Once Mobility Labs had created a master, high-level task list with development estimates – they
                  were able to work backwards from The World Bank’s timelines to surface risks, conflicts or critical
                  decision points.</p>
                  <p>By highlighting the weeks remaining in clearly defined ‘Sprints’ of work – the entire team was
                  able to discuss and decide which tasks would be left out of the first deliverable marker and which
                  tasks would be given priority to deliver the optimum user experience.</p>
                </li>
                <li>
                  <h3>2. Completed the first on-time delivery of project artifact (wireframes) and made
                  adjustments based on feedback from the team in less than 24 hours.</h3>
                  <p>Creating a clear communication process and deliverable expectation schedule gave the teams a
                  clear measuring stick to evaluate the success of this new process. Because of the clear meeting
                  expectations set ahead of time, Mobility Labs was able to deliver edits to their work in less than
                  24 hours and ahead of schedule for the project milestones.</p>
                </li>
              </ol>
            </section>
            <blockquote className="blockquote long">Mobility Labs’ mentality is to help stakeholders make decisions…not
            make the decisions for the stakeholders. Business leaders know what’s best for their company and Mobility
            Labs aims to surface risks, timelines and considerations in such a way that allows team leaders to more
            effectively lead their teams and plan for their future projects.</blockquote>
          </div>
          <div className="challenge" id="challenge-3">
            <div className="challenge-detail">
              <img className="img-fluid all-white" src={Challenge3} role="presentation"/>
              <h2>Challenge 3: No formal documentation or record of process evolution</h2>
              <p>Documentation is often considered an afterthought in technical projects, but without it – teams can
              feel powerless to solve problems on their own and are often forced to have repeat meetings and
              conversations to discuss issues or processes. Conversely, some teams go to the opposite extreme, and
              have excessive documentation that proves difficult to digest and navigate.</p>
              <p>At The World Bank, documentation was abound but the teams lacked a central repository for the
              direction of the features, timelines and milestones which led to misunderstandings and
              miscommunications.</p>
            </div>
            <section className="challenge-solution">
              <h2>How we solved this challenge</h2>
              <ol>
                <li>
                  <h3>1. Mobility Labs created a master project plan and established a new communication process.</h3>
                  <p>Because of the ‘assembly-line’ nature of development, some digital projects can bottleneck if
                  clear communication and direction is not present. Mobility Labs focused on solving this problem by
                  unifying all teams involved behind a common set of tasks and timelines and establishing a regular
                  communication process that did not impeded on the team’s ability to work.</p>
                  <p> Establishing a weekly deliverable schedule complete with ‘Demo Days’, feedback sessions and
                  prioritization meetings allowed all teams to plan their weeks based on solid expectations and
                  better prepare for their next tasks.</p>
                </li>
                <li>
                  <h3>2.Mobility Labs created clear expectations for the other contracting teams to work in a more
                  streamlined process.</h3>
                  <p>Unifying the project plan into a master plan allowed the development, design, research and
                  content teams the ability to better plan how to work together to accomplish the goals set for the
                  week, month and project end date.</p>
                  <p>By creating a streamlined process for all contract agents (including those working with The World
                  Bank outside of Mobility Labs), it allowed the project goals to supercede the needs of the
                  individual operations to provide a better unified development effort and complete more work in the
                  same amount of time.</p>
                </li>
              </ol>
            </section>
            <blockquote className="blockquote long">Mobility Labs focuses on Lean Development models that translate
            into lean documentation and project management. Creating straightforward documents that are constantly
            updated allows for a more consistent historical record to be kept of the project progress and a cleaner
            vision into the queue of remaining work.</blockquote>
          </div>
        </div>
      </CaseStudy>
    )
  }
}
