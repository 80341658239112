export default {
  "client": "The World Bank",
  "bannerImage": require("../../../img/mocks/evoke_hero.png"),
  "productImageSmall": require("../../../img/mocks/evoke-small.png"),
  "product": "Evoke 2.0",
  "services": [
    "Information Architecture",
    "UX Design",
    "Visual Design"
  ],
  "problemsSolved": [
    {
      "text": "Lack of central vision for the key requirements of the application",
      "href": "#challenge-1"
    },
    {
      "text": "Inability to forecast and plan deliverables",
      "href": "#challenge-2"
    },
    {
      "text": "No formal documentation or record of process evolution",
      "href": "#challenge-3"
    }
  ],
  "timeLine": [
    {
      "date": "May 2016",
      "summary": "Project kick off",
      "icon": "hourglass-start",
      "iconColor": "gray"
    },
    {
      "date": "June 2016",
      "summary": "User research with Colombian and US team",
      "icon": "pencil",
      "iconColor": "blue"
    },
    {
      "date": "November 2016",
      "summary": "First class goes through new and improved Evoke",
      "icon": "graduation-cap",
      "iconColor": "dark"
    }
  ]
}
